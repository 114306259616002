"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var bisect_1 = require("../../utils/bisect");
var widget_message_1 = require("../../components/widget-message");
var I18n_1 = require("../../I18n");
var layouts_1 = require("../../components/layouts");
var resource_api_1 = require("../../schemas/resource-api");
var widget_state_1 = require("../reducers/widget-state");
var chat_scroll_container_1 = require("../../components/chat-scroll-container");
var widget_chat_input_1 = require("./widget-chat-input");
var room_notification_utils_1 = require("../../utils/room-notification-utils");
var room_notification_message_1 = require("../../components/room-notification-message");
var file_upload_reducer_1 = require("../reducers/file-upload-reducer");
var scroll_bar_container_1 = require("../../components/scroll-bar-container");
var chat_room_reducer_1 = require("../reducers/chat-room-reducer");
var complete_request_reducer_1 = require("../../reducers/complete-request-reducer");
var fake_loading_1 = require("../../components/fake-loading");
var array_utils_1 = require("../../utils/array-utils");
var settings_page_reducer_1 = require("../reducers/settings-page-reducer");
var svgs_1 = require("../../components/svgs");
var color_editable_1 = require("../../components/color-editable");
var moment = require("moment");
var memoizers_1 = require("../../utils/memoizers");
var survey_content_1 = require("../../components/survey-content");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var select_input_1 = require("../../components/select-input");
function inBusinessHours(schedules) {
    var today = new Date();
    var day = today.getDay();
    var time = today.getHours() * 100 + today.getMinutes();
    return schedules.filter(function (schedule) {
        return schedule.day_of_week == day && time >= schedule.start_time && time <= schedule.end_time;
    }).length > 0;
}
function detectBusinessHour(schedules) {
    var now = new Date();
    var dayOfWeek = now.getDay();
    var detection = "after";
    schedules.some(function (schedule) {
        var startTime = ('0000' + schedule.start_time).slice(-4);
        var endTime = ('0000' + schedule.end_time).slice(-4);
        var formattedStartTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(startTime.toString().substr(0, 2)), parseInt(startTime.toString().substr(2, 2)), 0);
        var formattedEndTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(endTime.toString().substr(0, 2)), parseInt(endTime.toString().substr(2, 2)), 0);
        if (schedule.day_of_week == dayOfWeek) {
            if (startTime === endTime) {
                detection = "during";
                return true;
            }
            else {
                if (formattedStartTime > now || now > formattedEndTime) {
                    detection = "after";
                    return true;
                }
                else if (formattedStartTime <= now || now <= formattedEndTime) {
                    if (Math.floor((formattedEndTime.getTime() - now.getTime()) / 1000) <= 600) {
                        detection = "before_closing";
                        return true;
                    }
                    else {
                        detection = "during";
                        return true;
                    }
                }
            }
        }
    });
    return detection;
}
function nextSupportTime(schedules) {
    var date = new Date();
    var today = date.getDay();
    var currentTime = date.getHours() * 100 + date.getMinutes();
    var startTimesToday = schedules.filter(function (s) { return s.day_of_week === today && s.start_time > currentTime; });
    if (startTimesToday.length > 0) {
        var nextTime = startTimesToday.sort(function (a, b) { return a.start_time - b.start_time; })[0].start_time;
        var nextHours = nextTime / 100 | 0;
        var nextMinutes = nextTime % 100;
        return moment().startOf('day').add(nextHours, 'hours').add(nextMinutes, 'minutes');
    }
    var _loop_1 = function (i) {
        var day = (today + i) % 7;
        var startTimes = schedules.filter(function (s) { return s.day_of_week === day; });
        if (startTimes.length > 0) {
            var nextTime = startTimes.sort(function (a, b) { return a.start_time - b.start_time; }).sort()[0].start_time;
            var nextHours = nextTime / 100 | 0;
            var nextMinutes = nextTime % 100;
            return { value: moment().add(i, 'days').startOf('day').add(nextHours, 'hours').add(nextMinutes, 'minutes') };
        }
    };
    for (var i = 1; i < 7; i++) {
        var state_1 = _loop_1(i);
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return null;
}
var ChatRoom;
(function (ChatRoom) {
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    var newResource = resource_api_1.ResourceApi.newResource;
    function render(dispatch) {
        var dispatcher = {
            closeFailureMessage: function (notificationId) { return dispatch(file_upload_reducer_1.closeFailureMessage(notificationId)); },
            answerQuestion: function (questionId, content) { return dispatch(chat_room_reducer_1.answerQuestion(questionId, content)); },
            scrollTop: function (roomId) { return function () { return dispatch(chat_room_reducer_1.chatRoomTopScroll(roomId)); }; },
            saveEmail: function () { return dispatch(settings_page_reducer_1.saveEmail()); },
            downloadRoomCSV: function (currentViewingRoomId) { return dispatch(chat_room_reducer_1.downloadMessageSearchCSV(currentViewingRoomId)); },
            sendScenarioMessage: function (text, scenario_templates_id) { return dispatch(chat_room_reducer_1.scenarioAnswer(text, scenario_templates_id)); },
            scenarioTopList: function (roomId) { return dispatch(chat_room_reducer_1.scenarioTopList(roomId)); },
            scenarioBackList: function (roomId) { return dispatch(chat_room_reducer_1.scenarioBackList(roomId)); },
            scenarioSurveyAnswered: function (roomId, answer, scenario_survey_templates_id) { return dispatch(chat_room_reducer_1.scenarioSurveyAnswered(roomId, answer, scenario_survey_templates_id)); },
        };
        var ChatInput = widget_chat_input_1.WidgetChatInput.render(dispatch);
        return memoizers_1.memoizeBySomeProperties({
            roomInputs: widget_state_1.WidgetV2State.initialState.roomInputs,
            currentViewingRoomId: widget_state_1.WidgetV2State.initialState.currentViewingRoomId,
            loggedInUser: widget_state_1.WidgetV2State.initialState.loggedInUser,
            connectionErrors: widget_state_1.WidgetV2State.initialState.connectionErrors,
            toggles: widget_state_1.WidgetV2State.initialState.toggles,
            provider_login_mode: widget_state_1.WidgetV2State.initialState.provider_login_mode,
            isIOS: widget_state_1.WidgetV2State.initialState.isIOS,
            colors: widget_state_1.WidgetV2State.initialState.colors,
            stores: widget_state_1.WidgetV2State.initialState.stores,
            roomNotifications: widget_state_1.WidgetV2State.initialState.roomNotifications,
            nextPagedRequests: widget_state_1.WidgetV2State.initialState.nextPagedRequests,
            auto_posts: widget_state_1.WidgetV2State.initialState.auto_posts,
            notification_before_closing_business_hour: widget_state_1.WidgetV2State.initialState.notification_before_closing_business_hour,
            loadingPagedRequests: widget_state_1.WidgetV2State.initialState.loadingPagedRequests,
            schedules: widget_state_1.WidgetV2State.initialState.schedules,
            errorMessages: widget_state_1.WidgetV2State.initialState.errorMessages,
            fakeWidget: widget_state_1.WidgetV2State.initialState.fakeWidget,
            v2_time_format: widget_state_1.WidgetV2State.initialState.v2_time_format,
            collect_emails_description: widget_state_1.WidgetV2State.initialState.collect_emails_description,
            collect_emails_title: widget_state_1.WidgetV2State.initialState.collect_emails_title,
            collect_emails_error: widget_state_1.WidgetV2State.initialState.collect_emails_error,
            inputs: widget_state_1.WidgetV2State.initialState.inputs,
            localCsvDownloadUrl: widget_state_1.WidgetV2State.initialState.localCsvDownloadUrl,
            hide_download_link: widget_state_1.WidgetV2State.initialState.hide_download_link,
            thisTenant: widget_state_1.WidgetV2State.initialState.thisTenant,
            scenarioTemplatesData: widget_state_1.WidgetV2State.initialState.scenarioTemplatesData,
            scenarioEndSurveyDisplayedFlg: widget_state_1.WidgetV2State.initialState.scenarioEndSurveyDisplayedFlg,
            scenarioEndSurveyAnsweredFlg: widget_state_1.WidgetV2State.initialState.scenarioEndSurveyAnsweredFlg,
            selectedScenarioTemplatesIds: widget_state_1.WidgetV2State.initialState.selectedScenarioTemplatesIds,
            scenarioSurveyTemplateData: widget_state_1.WidgetV2State.initialState.scenarioSurveyTemplateData,
            plan_settings: widget_state_1.WidgetV2State.initialState.plan_settings,
        }, function (state) {
            var scenarioFlg = state.plan_settings.scenario_chat_flg;
            var messages = bisect_1.Indexer.getAllMatching(state.stores.messages.byRoomIdAndCreatedAtUnix, [state.currentViewingRoomId]);
            var roomNotifications = state.roomNotifications
                .filter(function (rn) { return (rn.attributes.roomId == state.currentViewingRoomId); })
                .sort(function (a, b) { return a.attributes.created_at_unix - b.attributes.created_at_unix; });
            var moreMessagesToBeLoaded = state.nextPagedRequests[array_utils_1.stringifyArray([chat_room_reducer_1.chatRoomHistoryRequestName, state.currentViewingRoomId])];
            var questions = bisect_1.Indexer.getAllMatching(state.stores.questions.byRoomId, [state.currentViewingRoomId])
                .filter(function (question) { return question.attributes.created_at_unix >= messages[0].attributes.created_at_unix
                || !moreMessagesToBeLoaded; });
            var rows = room_notification_utils_1.mergeChatRows(room_notification_utils_1.mergeChatRows(messages, roomNotifications), questions);
            var autoPosts = state.auto_posts;
            var language = state.loggedInUser.attributes.language;
            var isLoadingOldMessages = complete_request_reducer_1.areRequestsStillLoading(state.loadingPagedRequests, [array_utils_1.stringifyArray([chat_room_reducer_1.chatRoomHistoryRequestName, state.currentViewingRoomId])]);
            var offBusinessHours = !inBusinessHours(state.schedules);
            var showEmailCollection = state.loggedInUser.id && !state.loggedInUser.attributes.email && offBusinessHours;
            var showOffBusinessHoursAlert = state.loggedInUser.attributes.email && offBusinessHours;
            var hasEmailErrors = !!state.errorMessages.widgetSettingsPage.customerNotificationEmail;
            var nextSupportIn = nextSupportTime(state.schedules);
            var locale = language == "en" ? "en" : "ja";
            var willSupportIn = nextSupportIn && nextSupportIn.locale(locale).calendar();
            var lastQuestionSelectInput = bisect_1.Indexer.getAllMatching(state.stores.questions.byRoomId, [state.currentViewingRoomId])
                .sort(function (a, b) { return (b.attributes.created_at_unix || 0) - (a.attributes.created_at_unix || 0); })
                .filter(function (question) {
                var survey = bisect_1.Indexer.getFirstMatching(state.stores.surveys.byId, [getRelationshipId(question, "survey")]);
                return survey && survey.attributes.select_input;
            })[0];
            var lastSurveySelectInput = lastQuestionSelectInput && bisect_1.Indexer.getFirstMatching(state.stores.surveys.byId, [getRelationshipId(lastQuestionSelectInput, "survey")]);
            var detectedBusinessHour = detectBusinessHour(state.schedules);
            var isDisabled = Object.keys(state.connectionErrors).filter(function (k) { return state.connectionErrors[k] !== "success"; }).length > 0;
            var selectScenarioIds = [];
            if (scenarioFlg && state.currentViewingRoomId && state.selectedScenarioTemplatesIds) {
                var selectedScenarioTemplatesIds = state.selectedScenarioTemplatesIds.filter(function (item) { return item.roomid === Number(state.currentViewingRoomId); });
                selectScenarioIds = selectedScenarioTemplatesIds.length > 0 ? selectedScenarioTemplatesIds[0].ids : [];
            }
            return React.createElement(layouts_1.Column, { stretchColumn: true, className: "w-100 bblr3 bbrr3 test--chat-room-" + state.currentViewingRoomId + " " + (isDisabled ? 'pen' : '') },
                autoPosts || rows ?
                    React.createElement(scroll_bar_container_1.ScrollBarContainer, { colors: state.colors, inEditMode: state.fakeWidget.inEditMode },
                        React.createElement(chat_scroll_container_1.ChatScrollContainer, { className: " h-100 pv4 pl4 overflow-y-scroll no-focus-glow", tabIndex: -1, onScrollTop: dispatcher.scrollTop(state.currentViewingRoomId), isLoadingScrollTopRequest: state.loadingPagedRequests[array_utils_1.stringifyArray([chat_room_reducer_1.chatRoomHistoryRequestName, state.currentViewingRoomId])], roomId: state.currentViewingRoomId },
                            isLoadingOldMessages && React.createElement(fake_loading_1.FakeLoading, { colors: state.colors }),
                            ((state.hide_download_link.toString() === "false" && (messages.length > 0))) && React.createElement("div", { className: "dt mh2" },
                                React.createElement(color_editable_1.ColorEditable, { className: "dtc v-mid center ba br3 o-70 glow grow dim bg-animate pointer", onClick: function () { return dispatcher.downloadRoomCSV(state.currentViewingRoomId); }, onKeyDown: function (e) { return key_navigation_utils_1.isEnterOrSpace(e) && dispatcher.downloadRoomCSV(state.currentViewingRoomId); }, colors: state.colors, editedColors: {
                                        backgroundColor: "widget_csv_download_button_background",
                                        fill: "widget_csv_download_button_fill",
                                        color: "widget_csv_download_button_fill",
                                        outline: "outline"
                                    }, tabIndex: 0 },
                                    React.createElement("div", { className: "truncate tc ph2 pv1" },
                                        React.createElement(svgs_1.SVGDownload, { width: 14, height: 14 }))),
                                React.createElement(color_editable_1.ColorEditable, { className: "dtc v-mid pl2 f4", colors: state.colors, editedColors: { color: "message_date_text" } }, I18n_1.i18n.csvWidgetDownloadMessages[language])),
                            autoPosts && !moreMessagesToBeLoaded && autoPosts.map(function (autoPost, i) {
                                var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [autoPost.user_id + ""]);
                                var message = newResource("messages");
                                message.attributes = autoPost;
                                return React.createElement(widget_message_1.WidgetMessage, { key: "auto-messages-" + i, message: message, user: user, colors: state.colors, language: language, id: "test--chat-room-auto-message", tenant: state.thisTenant, timeFormat: state.v2_time_format });
                            }),
                            (state.notification_before_closing_business_hour && (detectedBusinessHour === 'before_closing')) ? React.createElement("div", { className: "pa2 mb4 mr4 f4 b--light-orange ba br3 dib bg-lightest-orange orange" }, state.notification_before_closing_business_hour) : null,
                            rows.length > 0 ?
                                rows.map(function (row) {
                                    if (row.type === "messages") {
                                        var message = row;
                                        var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [getRelationshipId(message, "user")]);
                                        return React.createElement(widget_message_1.WidgetMessage, { key: "message-" + message.id, message: message, user: user, language: language, colors: state.colors, id: "test--chat-room-message", timeFormat: state.v2_time_format, tenant: state.thisTenant });
                                    }
                                    else if (row.type === "room_notifications") {
                                        var roomNotification = row;
                                        return React.createElement("div", { className: "w-100 fr pv1", key: "room-notification-" + roomNotification.id },
                                            React.createElement(room_notification_message_1.RoomNotificationMessage, { roomNotification: roomNotification, onClose: dispatcher.closeFailureMessage, bar: false, colors: state.colors, colorKey: "progress_bar" }));
                                    }
                                    else if (row.type === "questions") {
                                        var question_1 = row;
                                        var survey = bisect_1.Indexer.getFirstMatching(state.stores.surveys.byId, [getRelationshipId(question_1, "survey")]);
                                        if (survey && survey.attributes.select_input && (!!question_1.attributes.answered_at_unix && !!question_1.attributes.answer)) {
                                            return survey && React.createElement(select_input_1.SelectInput, { key: "question-" + question_1.id, survey: survey, language: language, colors: state.colors, question: question_1, answerQuestion: function (content) { return dispatcher.answerQuestion(question_1.id, content); } });
                                        }
                                        else if (survey && !survey.attributes.select_input) {
                                            return React.createElement(survey_content_1.SurveyContent, { key: "question-" + question_1.id, survey: survey, language: language, colors: state.colors.outline, question: question_1, answerQuestion: function (content) { return dispatcher.answerQuestion(question_1.id, content); } });
                                        }
                                    }
                                })
                                : React.createElement("div", null, scenarioFlg ? "" : I18n_1.i18n.popupRoomEmptyMessage[language]),
                            scenarioFlg &&
                                React.createElement(React.Fragment, { key: "scenario-chat-aria" },
                                    React.createElement("div", { key: "scenario-choice-select" },
                                        (state.scenarioTemplatesData.length <= 0 && state.scenarioEndSurveyDisplayedFlg) &&
                                            React.createElement("div", { style: { color: "rgb(0, 0, 0)", borderTop: "1px solid gray" } },
                                                React.createElement("div", { key: "scenario-choice-end-survery-message", className: "bb db center tc f4 fw3 w-100 pa2 bn" }, state.scenarioSurveyTemplateData.question),
                                                React.createElement("div", { key: "scenario-choice-end-survery-choice", className: "w-100" }, state.scenarioSurveyTemplateData.input_kind === "button" && state.scenarioSurveyTemplateData.answer_template.map(function (item, j) {
                                                    return React.createElement("button", { disabled: isDisabled, className: "dib fl db center tc f4 fw3 w-40 pa2 bn pointer light-blue bg--light-blue--hover hover-white br4 " + (item.class || ''), key: "scenario-choice-end-survery-choice-text-" + item.value, style: { outline: "none", marginRight: "5%", marginLeft: "5%", marginBottom: "5px" }, onClick: function () { return dispatcher.scenarioSurveyAnswered(Number(state.currentViewingRoomId), item.value, state.scenarioSurveyTemplateData.id); } }, item.text);
                                                }))),
                                        (state.scenarioTemplatesData.length <= 0 && state.scenarioEndSurveyAnsweredFlg) &&
                                            React.createElement("div", { style: { color: "rgb(0, 0, 0)", borderTop: "1px solid gray" } },
                                                React.createElement("div", { className: "bb db center tc f4 fw3 w-100 pa2 bn", style: { marginBottom: "5px" } }, state.scenarioSurveyTemplateData.thank_you_message)),
                                        (!state.scenarioEndSurveyAnsweredFlg && !state.scenarioEndSurveyDisplayedFlg) &&
                                            React.createElement("div", { key: "scenario-choice-title-top", className: "cl bb db center tc f4 fw3 w-100 pa2 bn", style: { color: "rgb(0, 0, 0)", borderTop: "1px solid gray" } }, I18n_1.i18n.widgetScenarioChoice[language]),
                                        state.scenarioTemplatesData.map(function (scenarioItem, j) {
                                            return React.createElement("div", { key: "scenario-choice-" + j },
                                                React.createElement("button", { disabled: isDisabled, className: "db center tc f4 fw3 w-100 pa2 bn pointer light-blue bg--light-blue--hover hover-white", key: "scenario-choice-text-" + scenarioItem.id + "-" + j, style: { outlineColor: "", textAlign: "left" }, onClick: function () { return dispatcher.sendScenarioMessage(scenarioItem.title, scenarioItem.id); } },
                                                    React.createElement("div", { tabIndex: -1, className: "no-focus-glow", key: "scenario-choice-div-text-" + scenarioItem.id + "-" + j },
                                                        j + 1,
                                                        ".",
                                                        scenarioItem.title)));
                                        })),
                                    selectScenarioIds.length > 0 &&
                                        React.createElement(React.Fragment, { key: "scenario-chat-back" },
                                            React.createElement("div", { key: "scenario-choice-back" },
                                                React.createElement("button", { disabled: isDisabled, className: "db center tc f4 fw3 w-100 pa2 bn pointer light-blue bg--light-blue--hover hover-white", key: "scenario-choice-text-back", style: { outlineColor: "" }, onClick: function () { return dispatcher.scenarioBackList(Number(state.currentViewingRoomId)); } },
                                                    React.createElement("div", { tabIndex: -1, className: "no-focus-glow", key: "scenario-choice-div-text-back" }, I18n_1.i18n.widgetScenarioChoiceMoveBack[language]))),
                                            React.createElement("div", { key: "scenario-choice-top" },
                                                React.createElement("button", { disabled: isDisabled, className: "db center tc f4 fw3 w-100 pa2 bn pointer light-blue bg--light-blue--hover hover-white", key: "scenario-choice-text-top", style: { outlineColor: "" }, onClick: function () { return dispatcher.scenarioTopList(Number(state.currentViewingRoomId)); } },
                                                    React.createElement("div", { tabIndex: -1, className: "no-focus-glow", key: "scenario-choice-div-text-top" }, I18n_1.i18n.widgetScenarioChoiceMoveTop[language])))))))
                    : React.createElement("div", { className: "h-100 w-100" }),
                showOffBusinessHoursAlert && React.createElement("div", { className: "tc f4" }, willSupportIn ? I18n_1.i18n.widgetCollectEmailTimeDetail[language] + willSupportIn : I18n_1.i18n.widgetCollectEmailDetail[language]),
                showEmailCollection ?
                    React.createElement("div", { className: "w-100 bt tc b--moon-gray mr4 dib" },
                        React.createElement(color_editable_1.ColorEditable, { colors: state.colors, colorKeys: ["collect_emails_background", "collect_emails_text", "collect_emails_error"], editedColors: { backgroundColor: "collect_emails_background", color: "collect_emails_text" } },
                            React.createElement("div", { className: "pv3 tc f2 fw3" }, state.collect_emails_title || I18n_1.i18n.widgetCollectEmailCommand[language]),
                            React.createElement("div", { className: "pb3 tc f3 fw2" }, state.collect_emails_description || I18n_1.i18n.widgetCollectEmailMessage[language]),
                            hasEmailErrors && React.createElement("div", { className: "w-100 pv1 f4", style: { color: state.colors.collect_emails_error } }, state.collect_emails_error || I18n_1.i18n.emailInvalidErrorMessage[language])),
                        scenarioFlg ? "" : ChatInput(state))
                    :
                        (lastQuestionSelectInput && !lastQuestionSelectInput.attributes.answered_at_unix && !lastQuestionSelectInput.attributes.answer && lastSurveySelectInput ?
                            React.createElement(layouts_1.Row, null,
                                React.createElement(select_input_1.SelectInput, { key: "question-" + lastQuestionSelectInput.id, survey: lastSurveySelectInput, language: language, colors: state.colors, question: lastQuestionSelectInput, answerQuestion: function (content) { return dispatcher.answerQuestion(lastQuestionSelectInput.id, content); } }))
                            :
                                (scenarioFlg ? React.createElement("div", null) : ChatInput(state))));
        });
    }
    ChatRoom.render = render;
})(ChatRoom = exports.ChatRoom || (exports.ChatRoom = {}));
