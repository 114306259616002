"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sequence_services_1 = require("../../core/services/sequence-services");
var routes_1 = require("../../routes");
var json_resources_1 = require("../../core/json-resources");
var resource_api_1 = require("../../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var action_cable_services_1 = require("../../core/services/action-cable-services");
var room_list_reducer_1 = require("./room-list-reducer");
var chat_room_reducer_1 = require("./chat-room-reducer");
var tracker_checker_services_1 = require("../../core/services/tracker-checker-services");
var show_video_tag_services_1 = require("../../core/services/show-video-tag-services");
var events_1 = require("../events");
function setTrackerChecker(check_id) {
    if (check_id === void 0) { check_id = []; }
    return {
        type: 'set-tracker-checker',
        check_id: check_id
    };
}
exports.setTrackerChecker = setTrackerChecker;
function reduceTrackerCheck(state, action) {
    var _a, _b, _c, _d;
    var effects = [];
    var scenarioFlg = false;
    if (state.plan_settings.scenario_chat_flg) {
        scenarioFlg = state.plan_settings.scenario_chat_flg;
    }
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.sendTrackerCheckMessageRequestName) {
                state.tracker_check_get_message_ids = [];
                (_a = sequence_services_1.sequenceReduction(effects, loadAndFollowRooms(action, state)), state = _a.state, effects = _a.effects);
                (_b = sequence_services_1.sequenceReduction(effects, chat_room_reducer_1.loadFirstMessageCommon(action, state)), state = _b.state, effects = _b.effects);
                if (scenarioFlg) {
                    effects = sequence_services_1.sequence(effects, room_list_reducer_1.defaultCategories(state.client_id));
                    (_c = sequence_services_1.sequenceReduction(effects, chat_room_reducer_1.scenarioSelectList(state, 0, Number(state.currentViewingRoomId), 0)), state = _c.state, effects = _c.effects);
                }
                if (!state.toggles.widgetMaximized) {
                    events_1.chatOpenTracker();
                }
                effects = sequence_services_1.sequence(effects, tracker_checker_services_1.openChat());
            }
            break;
        case "set-tracker-checker":
            state = tslib_1.__assign({}, state);
            if (action.check_id && action.check_id !== null && action.check_id.length > 0) {
                state.tracker_check_get_message_ids = action.check_id;
            }
            if (state.tracker_check_get_message_ids && state.tracker_check_get_message_ids !== null && state.tracker_check_get_message_ids.length > 0) {
                state = tslib_1.__assign({}, state);
                state.inputs = tslib_1.__assign({}, state.inputs);
                state.chat_categories = {};
                state.scenarioTemplatesData = [];
                state.inputs.operatorSelectedCategories1Value = "";
                state.inputs.operatorSelectedCategories2Value = "";
                state.chat_categories_disabled = true;
                state.chat_first_display = false;
                effects = sequence_services_1.sequence(effects, show_video_tag_services_1.createShowVideoTagRootUrl([exports.sendTrackerCheckMessageRootUrlRequestName]));
            }
            break;
        case "response-show-video-tag-root-url":
            if (action.name[0] === exports.sendTrackerCheckMessageRootUrlRequestName) {
                state = tslib_1.__assign({}, state);
                state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
                state.isMessageSendingByRoom[state.currentViewingRoomId] = true;
                var message = newResource("messages");
                message.attributes.content = "";
                message.attributes.kind = "text";
                message.attributes.ids = state.tracker_check_get_message_ids;
                message.attributes.root_url = action.url;
                (_d = sequence_services_1.sequenceReduction(effects, sendTrackerCheckMessage(state, message, state.client_id)), state = _d.state, effects = _d.effects);
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceTrackerCheck = reduceTrackerCheck;
function loadAndFollowRooms(action, state) {
    var _a;
    var effects = [];
    var responseDetails = json_resources_1.getResourceResponseDetails(action);
    if (!responseDetails.resources)
        return { state: state, effects: effects };
    for (var i = 0; i < responseDetails.resources.length; i++) {
        var resource = responseDetails.resources[i];
        if (resource.type === "rooms") {
            var room = resource;
            effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("MemberChannel", "follow_room", { room_id: room.id }));
            (_a = sequence_services_1.sequenceReduction(effects, room_list_reducer_1.goToRoom(state, room.id)), state = _a.state, effects = _a.effects);
        }
    }
    return { state: state, effects: effects };
}
function sendTrackerCheckMessage(state, message, clientId) {
    var effects = [];
    addRelationship(message, "user", { type: "users", id: state.loggedInUser.id });
    var url = routes_1.RapiV1MessagesPath;
    url = url + "/create_recommend_message";
    if (clientId) {
        url += "?client_id=" + clientId;
    }
    else {
        console.error("Unable to send message need either a roomId or a clientId to send");
    }
    state = tslib_1.__assign({}, state);
    state.roomInputs = tslib_1.__assign({}, state.roomInputs);
    state.toggles = tslib_1.__assign({}, state.toggles);
    state.toggles.isCreatingANewRoom = true;
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.sendTrackerCheckMessageRequestName], message, url));
    return { state: state, effects: effects };
}
;
exports.sendTrackerCheckMessageRequestName = "send-tracker-check-message-request-name";
exports.sendTrackerCheckMessageRootUrlRequestName = "send-tracker-check-message-root-url-request-name";
